import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Card, Grid, Icon, Button } from "@mui/material";
import { Lock, LockOpen } from "@mui/icons-material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
// import DataTable from "components/DataTable";
import { makeStyles } from "@mui/styles";
import Searchbar from "components/Searchbar";
import { useAppContextController } from "context/AppContext";
// eslint-disable-next-line import/no-unresolved
import { useRosterApplicants } from "hooks/useRosterApplicants";
// import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchApplicantsOnly from "layouts/pages/applicants/actions/fetchApplicantsOnly";
// import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import EventRosterTable from "layouts/pages/events/components/EventRosterTable";
import InterviewEventFilterCards from "layouts/pages/events/components/InterviewEventFilterCards";
import TimeRosterFilterCards from "layouts/pages/events/components/TimeRosterFilterCards";
import PartnerEventTimeClockTable from "layouts/pages/events/components/PartnerEventTimeClockTable";
import useSort from "utils/useSort";
import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";
import rosterClockIn from "layouts/pages/events/actions/rosterClockIn";

// import EmployeeStatus from "components/EmployeeStatus";
import { getActiveFilterTitle, getApplicantVenueStatus } from "utils/helpers/applicant";
import EventStatusButton from "components/EventStatusButton";
import VenueStatusButton from "components/VenueStatusButton";
import ApplicantStatus from "components/ApplicantStatus";
import fetchPartners from "layouts/pages/partners/actions/fetchPartners";
import useSessionAuth from "hooks/useSessionAuth";
import { saveReport } from "api/reports/saveReport";
import { exportReport } from "api/reports/exportReport";
import {
  generateEventRosterChartReport,
  generateEventRosterTableReport,
} from "api/reports/applicantReport";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ReportModal from "components/ReportModal";
import EventRosterActions from "../EventRosterActions";
import { BulkMessagingModal } from "../BulkMessagingModal";
import { PartnerMembersRosterModal } from "../PartnerMembersRosterModal";
import EventRosterPartnersTable from "../EventRosterPartnersTable";
import fetchSingleEvent from "../../actions/fetchSingleEvent";
import EventRosterMembersTable from "../EventRosterMembersTable";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible",
  },
  button: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const EventRosterAndTime = ({
  id,
  actionSelected,
  setActionSelected,
  setFilters,
  filters,
  showFiltersList,
  setExportFieldModalOpen,
  setApplicantsData,
  setRosterData,
  setPartnerRosterData,
  rosterStatus,
  setRosterStatus,
  setToastAlert,
}) => {
  const queryClient = useQueryClient();
  const classes = useStyle();

  const { eventUrl } = useParams();

  const { currentEvent, venues, userType, currentLoggedUser, setCurrentEvent } =
    useAppContextController();
  const [applicantPoolSize, setApplicantPoolSize] = useState(0);
  const d = new Date();
  d.setDate(d.getDate() + 2);
  const today = d.toISOString().split("T")[0];

  const eventDay = new Date(currentEvent?.eventDate).getTime() <= new Date(today).getTime();
  const [roster, setRoster] = useState("Signups");
  const [rosterIds, setRosterIds] = useState([]);
  const [rosterFilters, setRosterFilters] = useState({ status: "" });
  const [readRoster, setReadRoster] = useState(false);
  const [readApps, setReadApps] = useState(false);
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [selectedCardNumber, setSelectedCardNumber] = useState(0);
  const [refreshBadges, setRefreshBadges] = useState(false);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const { order, orderBy, toggleSort } = useSort();
  const options = {
    fetchAll: true,
    page,
    limit,
    order,
    orderBy,
    useElemMatch: currentEvent?.eventType !== "Interview",
  };

  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { logoutAuth0User } = useSessionAuth();

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const { mutateAsync: createEventRosterTableReport } = useMutation(generateEventRosterTableReport);
  const { mutateAsync: createEventRosterChartReport } = useMutation(generateEventRosterChartReport);
  const { mutateAsync: exportEventRosterReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveEventRosterReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialEventRosterPoolReport = useCallback(async () => {
    if (openReportMessageModal) {
      try {
        const response = await fetchModuleReports(`event-roster:${eventUrl}`);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialEventRosterPoolReport();
  }, [handleLoadInitialEventRosterPoolReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportEventRosterReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Event Roster ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportEventRosterReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        if (eventUrl) {
          reportPayload = {
            ...reportPayload,
            eventUrl,
          };

          await createEventRosterTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: `Event Roster Table Report has been successfully generated!`,
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        if (eventUrl) {
          reportPayload = {
            ...reportPayload,
            eventUrl,
          };

          await createEventRosterChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: `Event Roster Chart Report has been successfully generated!`,
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        if (eventUrl) {
          _tableReportData = {
            ..._tableReportData,
            feature: `event-roster:${eventUrl}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `event-roster:${eventUrl}`,
          };
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveEventRosterReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Event Roster ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error(`Error saving event roster report:`, error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveEventRosterReport, tableReportData]
  );

  const isPartners = rosterFilters?.status === "Partner";
  const isMembers = rosterFilters?.status === "Member";

  const applicantOptions = {
    ...options,
    filters: { ...filters, "venues.venueSlug": currentEvent?.venueSlug },
    // TODO:  Create filter includeEmployees that also fetches employees who are included in this event
  };
  if (
    currentEvent?.eventType !== "Interview" &&
    rosterFilters &&
    (!rosterFilters.status || rosterFilters.status === "Not Roster")
  ) {
    applicantOptions.filters.status = "Employee";
    applicantOptions.filters["venues.status"] = "StaffingPool";
  }

  if (currentEvent?.eventDate && new Date().toISOString() > currentEvent.eventDate) {
    if (applicantOptions.filters.employmentStatus) {
      applicantOptions.filters.employmentStatus = `${applicantOptions.filters.employmentStatus};Terminated`;
    } else {
      applicantOptions.filters.employmentStatus = "On Assignment;Active;Inactive;Terminated";
    }
  }

  const eventVenue = useMemo(
    () => venues?.[currentEvent?.venueSlug] || {},
    [currentEvent?.venueSlug, venues]
  );

  const {
    data: rosterApplicants,
    isLoading: rosterLoading,
    isRefetching,
    refetch: requeryRoster,
  } = useRosterApplicants({
    options: {
      onSuccess: (data) => {
        if (data.result) {
          setRosterIds(
            data?.result?.map((item) => item.id) // .filter((item) => item.status === "Roster")?
          );
          setRosterData(data?.result.filter((item) => ["Roster"].includes(item.status)));
        } else {
          setRosterIds([]);
        }
      },
    },
  });

  // ---- PARTNERS ----
  const [isPartnerMembersModalOpen, setPartnerMembersModalOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState(null);

  const handleSelectPartnerMembers = (row) => {
    setSelectedPartner(row);
    setPartnerMembersModalOpen(true);
  };

  const handleClosePartnerMembersModal = () => {
    setSelectedPartner(null);
    setPartnerMembersModalOpen(false);
  };

  const {
    data: rosterPartnersData,
    isLoading: rosterPartnersLoading,
    isRefetchingRosterPartners,
    refetch: requeryRosterPartners,
  } = useQuery(
    ["rosterPartners", currentEvent?._id],
    () => fetchSingleEvent({ eventId: currentEvent?._id }),
    {
      onSuccess: (data) => {
        setPartnerRosterData(data?.[1] ?? []);
      },
      select: (data) => {
        const memberRoster = {};
        const appRoster = data?.applicants ?? [];
        let takenPositions = 0;
        for (let index = 0; index < appRoster.length; index += 1) {
          const currAppRoster = appRoster[index];
          if (currAppRoster.status === "Roster") takenPositions += 1;
          if (currAppRoster.partnerSlug) {
            if (memberRoster[currAppRoster.partnerSlug] == null)
              memberRoster[currAppRoster.partnerSlug] = [];
            memberRoster[currAppRoster.partnerSlug].push(currAppRoster);
          }
        }
        return [memberRoster, data?.partners ?? [], takenPositions];
      },
      enabled: currentEvent?._id && isPartners,
    }
  );

  // const memberRoster = rosterPartnersData?.[0];
  const rosterPartners = rosterPartnersData?.[1];
  // const takenPositions = rosterPartnersData?.[2];

  const positionsRequested = currentEvent?.positionsRequested;
  // const availablePositions = Math.max(positionsRequested - takenPositions, 0);

  // ---- END PARTNERS ----

  const insertRosterMutation = useMutation(updateEventRoster, {
    // eslint-disable-next-line
    onSuccess: async (_, { data }) => {
      // await queryClient.invalidateQueries("rosterApplicants")
      const newApps = currentEvent?.applicants;
      if (newApps?.length) {
        const ndx = newApps.findIndex((item) => item.id === data.id);
        if (ndx > -1) {
          newApps[ndx] = data;
          setCurrentEvent({ ...currentEvent, applicants: newApps });
        }
      }
      requeryRoster();
    },
    onError: (error) => alert(error.toString()),
  });

  //
  const clockInRosterMutation = useMutation(rosterClockIn, {
    // eslint-disable-next-line
    onSuccess: async (_, { data }) => {
      // await queryClient.invalidateQueries("rosterApplicants")
      const newApps = currentEvent?.applicants;
      if (newApps?.length) {
        const ndx = newApps.findIndex((item) => item.id === data.id);
        if (ndx > -1) {
          newApps[ndx] = data;
          setCurrentEvent({ ...currentEvent, applicants: newApps });
        }
      }
      requeryRoster();
    },
    onError: (error) => alert(error.toString()),
  });

  const clockInOut = async (e, _, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    } else newRoster[field] = newRoster[field] || new Date();
    await insertRosterMutation.mutateAsync({ id: currentEvent?._id, data: newRoster });
  };

  //
  const clockInOutAtEventTime = async (e, _, field, rosterObj, edit = false) => {
    if (e) {
      e.stopPropagation();
    }
    // eslint-disable-next-line
    // await deleteRosterMutation.mutateAsync({ id });
    const newRoster = { ...rosterObj };
    if (newRoster[field] === "") {
      newRoster[field] = null;
    } else newRoster[field] = newRoster[field] || new Date();
    await clockInRosterMutation.mutateAsync({
      id: currentEvent?._id,
      applicantId: rosterObj.id,
      data: newRoster,
    });
  };

  const {
    data: applicants,
    isLoading: appLoading,
    isRefetching: isRefetchingApps,
    refetch: requeryApplicants,
  } = useQuery(
    ["applicants", applicantOptions],
    () =>
      currentEvent?.eventType === "Interview"
        ? fetchApplicantsOnly(applicantOptions)
        : fetchStaffingPool(applicantOptions),
    {
      onSuccess: (retValue) => {
        requeryRoster();
      },
    }
  );

  const toggleReadApps = () => requeryApplicants(); // queryClient.invalidateQueries(["applicant", applicantOptions]);

  const toggleRefreshBadges = () => setRefreshBadges((p) => !p);

  useEffect(() => {
    toggleReadApps();
    requeryRoster();
  }, [rosterFilters]);

  const isOnRoster = (applId, i) => {
    const returnValue = rosterIds.findIndex((item) => item === applId) > -1;
    return returnValue;
  };

  const columns = useMemo(
    () => [
      {
        title: "Roster",
        field: "_id",
        width: 15,
        customCell: (check, row, field) => {
          const eventApplicant =
            rosterApplicants?.result?.find((item) => item.id === row?._id) || null;
          const eventStatus = eventApplicant?.status || "Not Roster";
          const applicantVenueStatus = getApplicantVenueStatus(row, currentEvent?.venueSlug);
          const isInvalid = applicantVenueStatus === "Locked" || row?.recordLocked === "Yes";
          return (
            <>
              <EventStatusButton
                status={eventStatus}
                isInvalid={isInvalid}
                applicant={row}
                event={{ _id: currentEvent?._id, eventUrl, applicants: rosterApplicants?.result }}
                onUpdate={() => requeryRoster()}
                iconType="distinct"
              />
            </>
          );
        },
      },
      ...(["Master", "Admin"].includes(userType)
        ? [
            {
              title: "Locked",
              field: "recordLocked",
              width: 15,
              customCell: (recordLocked) => (
                <Box pl={0.75}>
                  {recordLocked === "Yes" ? (
                    <Lock fontSize="medium" color="error" />
                  ) : (
                    <LockOpen fontSize="medium" color="success" />
                  )}
                </Box>
              ),
            },
          ]
        : []),

      {
        title: "Venue",
        field: "venues",
        width: 15,
        customCell: (venue, row) => (
          <>
            <VenueStatusButton
              status={getApplicantVenueStatus(row, currentEvent?.venueSlug)}
              venue={eventVenue}
              onUpdate={() => requeryApplicants()}
              applicant={row}
            />
          </>
        ),
      },
      // {
      //   title: "Status",
      //   field: "profileImg",
      //   customCell: (image, field, row) => <EmployeeStatus applicant={row} size="md" />,
      // },

      {
        title: "Last Name",
        field: "lastName",
        customCell: (name, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      {
        title: "First Name",
        field: "firstName",
        customCell: (name, row) =>
          row ? (
            <CustomWidthTooltip
              title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
              sx={{ color: "red" }}
            >
              <div>{name}</div>
            </CustomWidthTooltip>
          ) : null,
      },
      { title: "City", field: "city" },
      { title: "State", field: "state" },
      ...(currentEvent?.eventType === "Interview"
        ? [
            { title: "Type", field: "status" },
            {
              title: "Applicant Status",
              field: "applicantStatus",
              customCell: (image, field, row) => <ApplicantStatus applicant={row} size="lg" />,
            },
          ]
        : [{ title: "Rank", field: "rank" }]),
      { title: "Email", field: "email" },
      ...(currentEvent?.eventType === "Event"
        ? [
            {
              title: "Position",
              field: "primaryPosition",
            },
          ]
        : []),
    ],
    [
      userType,
      currentEvent?.eventType,
      currentEvent?.venueSlug,
      currentEvent?._id,
      rosterApplicants?.result,
      eventUrl,
      requeryRoster,
      eventVenue,
      requeryApplicants,
    ]
  );

  const requeryPartners = useCallback(async () => {
    await queryClient.invalidateQueries(["eventPartners"]);
  }, [queryClient]);

  const columnsPartners = useMemo(
    () => [
      {
        title: "Roster",
        field: "_id",
        width: 15,
        customCell: (check, row, field) => {
          const eventApplicant =
            (rosterPartners ?? []).find((item) => item.id === row?._id) || null;
          const eventStatus = eventApplicant?.status || "Not Roster";
          const applicantVenueStatus = getApplicantVenueStatus(row, currentEvent?.venueSlug);
          const isInvalid = applicantVenueStatus === "Locked" || row?.recordLocked === "Yes";
          return (
            <>
              <EventStatusButton
                status={eventStatus}
                isInvalid={isInvalid}
                applicant={row}
                event={{ _id: currentEvent?._id, eventUrl, applicants: rosterPartners }}
                onUpdate={() => {
                  requeryRosterPartners();
                  toggleRefreshBadges?.();
                }}
                iconType="distinct"
                isPartner
              />
            </>
          );
        },
      },
      {
        title: "Venue",
        field: "venues",
        width: 15,
        customCell: (venue, row) => (
          <>
            <VenueStatusButton
              status={getApplicantVenueStatus(row, currentEvent?.venueSlug)}
              venue={eventVenue}
              onUpdate={() => requeryPartners()}
              applicant={row}
              isPartner
            />
          </>
        ),
      },
      {
        title: "Name",
        field: "name",
      },
      {
        title: "Leader Email",
        field: "email",
        customCell: (venue, row) => <>{row?.leader?.email}</>,
      },
      {
        title: "Leader Phone",
        field: "phone",
        customCell: (venue, row) => <>{row?.leader?.Phone}</>,
      },
    ],
    [
      rosterPartners,
      currentEvent?.venueSlug,
      currentEvent?._id,
      eventUrl,
      requeryRosterPartners,
      eventVenue,
      requeryPartners,
    ]
  );

  const navigate = useNavigate();
  const addNew = () => {
    navigate("/partners/create");
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  const sortLastFirstPartners = (a, b) => {
    const o1 = a.name.toLowerCase();
    const o2 = b.name.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    return 0;
  };

  const searchFetchFunction = useMemo(() => {
    if (isPartners) {
      return fetchPartners;
    }
    if (currentEvent?.eventType === "Interview") {
      return fetchApplicantsOnly;
    }

    return fetchStaffingPool;
  }, [currentEvent?.eventType, isPartners]);

  const [
    isPartnerInRoster,
    currentPartnerSecondaryPosition,
    currentPartnerSecondaryPositionStartTime,
  ] = useMemo(() => {
    if (selectedPartner) {
      const selectedEventPartner =
        (rosterPartners ?? []).find((item) => item.id === selectedPartner._id) || null;
      return [
        !!selectedEventPartner,
        selectedEventPartner?.primaryPosition ?? null,
        selectedEventPartner?.reportTime ?? null,
      ];
    }
    return [false, null];
  }, [rosterPartners, selectedPartner]);

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item xs={6}>
          <MDBox display="flex" flexWrap="wrap">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="error"
              color="white"
              borderRadius="xl"
              mt={-4}
              ml={3}
            >
              <Icon fontSize="large">people</Icon>
            </MDBox>

            <MDBox>
              <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                Event Roster - {roster}
                {` - Applicant Pool Size: ${applicantPoolSize || 0}`}
              </MDTypography>

              <MDTypography variant="h6" color="dark" sx={{ ml: 2 }}>
                {`${currentEvent?.eventName} at ${moment(currentEvent?.eventDate).format(
                  "ddd, MMM DD, YYYY hh:mm a"
                )}`}
              </MDTypography>
            </MDBox>
          </MDBox>
        </Grid>

        <Grid item xs={3}>
          {!isMembers && (
            <Searchbar
              fetch={searchFetchFunction}
              placeholder={isPartners ? "Add Partners" : "Add Members"}
              columns={isPartners ? columnsPartners : columns}
              queryCharacterLimit={2}
              resultsLimit={20}
              scrollLimit={200}
              setFilters={setFilters}
              sortFunction={isPartners ? sortLastFirstPartners : sortLastFirst}
              setPage={setPage}
              searchBy={
                isPartners ? ["name", "email", "leader.email"] : ["firstName", "lastName", "email"]
              }
              filterBy="email"
              refetchDependency={applicants}
            />
          )}
        </Grid>
        <Grid item xs={3} textAlign="center">
          <EventRosterActions
            id={id}
            setRoster={setRoster}
            setFilters={setFilters}
            currentEvent={currentEvent}
            setExportFieldModalOpen={setExportFieldModalOpen}
            toggleBulkMessageModal={toggleBulkMessageModal}
            isPartners={isPartners || isMembers}
            eventUrl={eventUrl}
            handleOpenReportModal={() => setOpenReportMessageModal(true)}
          />
        </Grid>
      </Grid>

      <Box display="flex" pl={3} mt={2} mb={3}>
        {currentEvent?.eventType !== "Interview" ? (
          <TimeRosterFilterCards
            setFilters={setRosterFilters}
            selectedCardNumber={selectedCardNumber}
            rosterFilters={rosterFilters}
            setRosterStatus={setRosterStatus}
            refreshBadges={refreshBadges}
          />
        ) : (
          <InterviewEventFilterCards
            setFilters={setRosterFilters}
            selectedCardNumber={selectedCardNumber}
            rosterFilters={rosterFilters}
          />
        )}
      </Box>
      <MDBox>
        <>
          {(roster === "Signups" || roster === "") && (
            <Grid item xs={12} sm={12}>
              {isPartners && (
                <EventRosterPartnersTable
                  rosterPartners={rosterPartners}
                  requeryRosterPartners={requeryRosterPartners}
                  toggleRefreshBadges={toggleRefreshBadges}
                  onOpenMembers={handleSelectPartnerMembers}
                />
              )}
              {isMembers && (
                <EventRosterMembersTable
                  allApplicantsRoster={rosterApplicants}
                  requeryRoster={requeryRoster}
                  toggleRefreshBadges={toggleRefreshBadges}
                  onOpenMembers={handleSelectPartnerMembers}
                />
              )}
              {!isPartners && !isMembers && (
                <EventRosterTable
                  name="Events Time Roster"
                  fetchAll
                  // filters={{ "venues.venueSlug": currentEvent?.venueSlug }}
                  rosterFilters={rosterFilters}
                  showEventStatus
                  setApplicantPoolSize={setApplicantPoolSize}
                  eventDay={eventDay}
                  applicants={applicants}
                  rosterApplicants={rosterApplicants}
                  rosterLoading={rosterLoading}
                  appLoading={appLoading}
                  defaultSort="lastName"
                  rosterIds={!rosterLoading ? rosterIds : []}
                  isOnRoster={isOnRoster}
                  setApplicantsData={setApplicantsData}
                  readRoster={readRoster}
                  setReadRoster={setReadRoster}
                  readApps={readApps}
                  setReadApps={setReadApps}
                  toggleReadApps={toggleReadApps}
                  toggleReadRoster={requeryRoster}
                  toggleRefreshBadges={toggleRefreshBadges}
                  setSelectedCardNumber={setSelectedCardNumber}
                  requeryApplicants={requeryApplicants}
                  clockInOut={clockInOut}
                  clockInOutAtEventTime={clockInOutAtEventTime}
                />
              )}
            </Grid>
          )}
          {roster === "Partners" && (
            <Grid item xs={12} sm={12}>
              <PartnerEventTimeClockTable
                setActionSelected={setActionSelected}
                showFiltersList
                filters={filters}
                setFilters={setFilters}
              />
            </Grid>
          )}
        </>
      </MDBox>
      {isBulkMessageModalOpen && (
        <BulkMessagingModal
          applicantFilter={rosterFilters}
          isOpen={isBulkMessageModalOpen}
          toggleModal={toggleBulkMessageModal}
          currentEvent={currentEvent}
          messageContext="Event"
          attachmentContext="Event"
          eventVenue={eventVenue}
          recipientText={getActiveFilterTitle(rosterFilters.filt)}
        />
      )}
      {isPartnerMembersModalOpen && (
        <PartnerMembersRosterModal
          isOpen={isPartnerMembersModalOpen}
          toggleModal={handleClosePartnerMembersModal}
          partner={selectedPartner}
          allMemberRoster={rosterApplicants}
          clockInOut={clockInOut}
          clockInOutAtEventTime={clockInOutAtEventTime}
          positionsRequested={positionsRequested}
          isPartnerInRoster={isPartnerInRoster}
          eventDay={eventDay}
          toggleRefreshBadges={toggleRefreshBadges}
          defaultStartTime={currentPartnerSecondaryPositionStartTime}
        />
      )}

      {openReportMessageModal && (
        <ReportModal
          reportingType="applicants"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          filterCardList={[
            "All",
            "On Roster",
            "Request",
            "Waitlist",
            "Not On Roster",
            "Partners",
            "Members",
          ]}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};

EventRosterAndTime.defaultProps = {
  showFiltersList: 0,
};

// Typechecking props
EventRosterAndTime.propTypes = {
  showFiltersList: PropTypes.bool,
};

export default EventRosterAndTime;
