import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useAppContextController } from "context/AppContext";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import EventDate from "components/EventDate";
import fetchEvents from "layouts/pages/events/actions/fetchEvents";
import fetchInterviewEvents from "layouts/pages/events/actions/fetchInterviewEvents";
import ApplicantVenueStatus from "layouts/pages/events/components/ApplicantVenueStatus";

import ListIcon from "@mui/icons-material/List";
import FiltersList from "components/FiltersList";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import moment from "moment";
import useSort from "utils/useSort";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DateRangeIcon from "@mui/icons-material/DateRange";
import axios from "axios";
import CheckExWidget from "components/CheckExWidget";
import { baseAxios } from "config";
import EventCalendar from "layouts/pages/events/components/EventCalendar";
import JobsEventsTableActions from "layouts/pages/jobs/components/JobsEventsTableActions";

import { useSnackbar } from "notistack";
import useSessionAuth from "hooks/useSessionAuth";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import {
  generateJobInterviewChartReport,
  generateJobInterviewTableReport,
} from "api/reports/eventReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const JobsEventsTable = ({
  // eventUrl,
  navigateToAction,
  title = "Events",
  subtitle,
  filters,
  setFilters,
  fetchAll,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  showEventStatus = false,
  setOpen,
  setEventPreview,
  defaultSort = "eventDate",
  setOpenEventPreview,
  parent = "Events",
  setToastAlert,
}) => {
  const classes = useStyle();
  const {
    venues,
    currentEvent,
    setCurrentEvent,
    setStateVar,
    currentApplicant,
    currentJob,
    company,
  } = useAppContextController();
  const navigate = useNavigate();
  const [calendarListView, setCalendarListView] = useState("List");

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const { order, orderBy, toggleSort } = useSort();

  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { logoutAuth0User } = useSessionAuth();

  const { mutateAsync: createJobInterviewTableReport } = useMutation(
    generateJobInterviewTableReport
  );
  const { mutateAsync: createJobInterviewChartReport } = useMutation(
    generateJobInterviewChartReport
  );
  const { mutateAsync: exportJobInterviewReport, isLoading: isLoadingExport } =
    useMutation(exportReport);
  const { mutateAsync: saveJobInterviewReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    filters: { ...filters, eventType: "Interview", status: "Active" },
    venues,
  };
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: events,
    isLoading,
    refetch,
  } = useQuery(["events", allOptions], () => {
    return fetchEvents(allOptions);
  });

  const { action, eventUrl } = useParams();

  const sortByEventDate = (a, b) => {
    if (new Date(a.eventDate) > new Date(b.eventDate)) return 1;
    return -1;
  };

  useEffect(() => {
    if (!isLoading && events?.data?.length && eventUrl) {
      const ndx = events.data.findIndex((item) => item.eventUrl === eventUrl);
      if (ndx > -1) {
        setCurrentEvent(events.data[ndx]);
      }
    }
    if (action && eventUrl) setFilters((prev) => ({ ...prev, eventUrl }));
  }, [isLoading, eventUrl, action]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialJobReport = useCallback(async () => {
    if (openReportMessageModal && process.env.REACT_APP_ENV !== "production") {
      try {
        const response = await fetchModuleReports(`job-interviews:${filters?.jobSlug}`);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );
        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialJobReport();
  }, [handleLoadInitialJobReport]);

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportJobInterviewReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Job Interview ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportJobInterviewReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        reportPayload = {
          ...reportPayload,
          jobSlug: filters?.jobSlug,
        };

        await createJobInterviewTableReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartTableSrc(response?.iframe_url);
              setTableQueryDetails(response?.queryDetails);
              setTableReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Job Interview Table Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        reportPayload = {
          ...reportPayload,
          jobSlug: filters?.jobSlug,
        };

        await createJobInterviewChartReport(reportPayload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: "Job Interview Chart Report has been successfully generated!",
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const handleSaveJobReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        _tableReportData = {
          ..._tableReportData,
          feature: `job-interviews:${filters?.jobSlug}`,
        };

        _chartReportData = {
          ..._chartReportData,
          feature: `job-interviews:${filters?.jobSlug}`,
        };

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveJobInterviewReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Job Interview ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving job report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveJobInterviewReport, tableReportData]
  );

  const columns = useMemo(
    () => [
      ...(company?.companyType === "Venue"
        ? [
            {
              title: "Logo",
              field: "logoUrl",
              customCell: (logoUrl, row) => (
                <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />
              ),
            },
          ]
        : [
            {
              title: "Logo",
              field: "name",
              customCell: (name, field, row) => (
                <CompanyLogoSquare company={row} defaultCompany={company} />
              ),
            },
            {
              title: "Company",
              field: "companySlug",
              customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
            },
          ]),
      ...(showEventStatus && currentApplicant
        ? [
            {
              title: "Event",
              field: "eventUrl",
              customCell: (vslug, row) => (
                <ApplicantVenueStatus
                  id={row?._id}
                  venue={vslug}
                  slugField="eventUrl"
                  row={row}
                  invalidQuery={["events", allOptions]}
                />
              ),
            },
          ]
        : []),
      ...(company?.companyType === "Venue"
        ? [
            {
              title: "Venue",
              field: "venueSlug",
              customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
            },
          ]
        : []),
      // { title: "Event Url", field: "eventUrl" },
      { title: "Event", field: "eventName" },
      {
        title: "Event Date/Report Time",
        field: "eventDate",
        customCell: (date, field, row) => (
          <EventDate date={date} reportTimeTBD={row?.reportTimeTBD} />
        ),
      },
      // {
      //   title: "Report Time",
      //   field: "eventDate",
      //   customCell: (time) => moment(time).format("hh:mm a"),
      // },

      { title: "Status", field: "status" },
      {
        title: "# Requested",
        field: "positionsRequested",
        customCell: (number) => (
          <div style={{ textAlign: "right", paddingRight: "20px" }}>{number}</div>
        ),
      },
      {
        title: "Interview Actions",
        field: "eventUrl",
        customCell:
          parent === "Events"
            ? (url, field, row) => (
                <>
                  <JobsEventsTableActions
                    setNavigateUrl={navigate}
                    eventUrl={url}
                    row={row}
                    setFilters={setFilters}
                    setActionSelected={setActionSelected}
                    setOpen={setOpen}
                    setEventPreview={setEventPreview}
                    setCurrentEvent={setCurrentEvent}
                  />
                </>
              )
            : (url, field, row) => (
                <JobsEventsTableActions
                  setNavigateUrl={navigate}
                  eventUrl={url}
                  row={row}
                  setFilters={setFilters}
                  setActionSelected={setActionSelected}
                  setOpen={setOpen}
                  setEventPreview={setEventPreview}
                  setCurrentEvent={setCurrentEvent}
                />
              ),
      },
    ],
    [currentEvent]
  );

  const updateSelected = async (value, id) => {
    try {
      const newJobs = currentJob?.jobs ? [...currentJob.jobs] : [];
      if (value) {
        newJobs.push(currentJob.jobSlug);
      } else {
        const ndx = newJobs.findIndex((item) => item === currentJob.jobSlug);
        if (ndx > -1) newJobs.slice(ndx, 1);
      }
      const updateEventUrl = `/events/${id}`;
      const res = await baseAxios.put(updateEventUrl, { jobs: [...newJobs] });
      if (res?.data?.success) {
        enqueueSnackbar(
          `Interview has been ${value ? "added" : "removed"} for ${currentJob?.jobTitle}`,
          { variant: "success" }
        );
      } else {
        enqueueSnackbar(`Something went wrong`, { variant: "error" });
      }
      refetch();
    } catch (error) {
      if (String(error).includes("401") || error?.response?.status === 401) {
        logoutAuth0User();
      }
    }
  };
  const interviewColumns = useMemo(
    () => [
      {
        title: "Selected",
        field: "jobs",
        customCell: (jobs, row) => (
          <CheckExWidget
            yes={currentJob?.jobSlug && jobs?.length && jobs.includes(currentJob.jobSlug)}
            updateValue={updateSelected}
            id={row._id}
          />
        ),
      },
      {
        title: "Logo",
        field: "venueSlug",
        customCell: (slug, row) => (
          <VenueIcon logoUrl={venues?.[slug]?.logoUrl} slug={slug} name={row?.venueName} />
        ),
      },
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
      },
      {
        title: "Interview",
        field: "eventName",
      },
      {
        title: "City/State",
        field: "venueCity",
        customCell: (city, event) => (
          <span>{`${city}, ${event?.venueState ? event.venueState : ""}`}</span>
        ),
      },
      {
        title: "Date",
        field: "eventDate",
        customCell: (date) => moment(date).format("MM/DD/YY hh:mm a"),
      },
    ],
    []
  );

  const addNew = (e) => {
    if (!currentEvent) {
      setCurrentEvent({});
    }
    const url = new URL(window.location).pathname;
    const { pathname } = url;
    const nodes = url.split("/");
    if (nodes.length === 4 && nodes[1] === "events" && nodes[2] === "venue") {
      navigate(`/events/create/${nodes[3]}`);
    } else if (nodes.length === 3 && nodes[1] === "events" && nodes[2]) {
      const parts = nodes[2].split("-");
      navigate(`/events/create/${parts[0]}`);
    } else navigate("/events/create");
    e.stopPropagation();
  };

  const onRowClick = (row, e) => {
    e.stopPropagation();
    setCurrentEvent(row);
    const basePath = new URL(window.location).pathname;
    const nodes = basePath.split("/");
    if (nodes?.length >= 4 && nodes[2] === "venue") {
      navigate(`/events/venue/${row?.venueSlug}/${row?.eventUrl}`);
    } else
      navigate(`/events/${row?.eventUrl}${navigateToAction ? `/action/${navigateToAction}` : ""}`);
  };

  const handleViewClick = () =>
    setCalendarListView((prev) => (prev === "List" ? "Calendar" : "List"));

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
        <Grid item sm={6}>
          <MDBox display="flex" flexWrap="wrap">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="success"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-4}
            >
              <Icon fontSize="large">event-seat</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }} whiteSpace="nowrap">
              {title} - {calendarListView} View{" "}
              {events?.data?.length
                ? `- ${events.data.length} Interview${events.data?.length > 1 ? "s" : ""}`
                : ""}
            </MDTypography>
            {subtitle && (
              <MDTypography variant="h5" color="dark" sx={{ ml: 2, pl: 11 }} whiteSpace="nowrap">
                {subtitle}
              </MDTypography>
            )}
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item xs={12}>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item sm={6} alignItems="center">
          <Grid container direction="row" alignItems="flex-end" justifyContent="space-between">
            {showSearchBar && (
              <Grid item sm={6}>
                <Grid container spacing={2}>
                  {/* <Grid item sm={6}>
                    <Searchbar
                      fetch={fetchEvents}
                      fetchAll
                      placeholder="Search Event"
                      columns={columns.slice(1, columns.length - 1)}
                      queryCharacterLimit={1}
                      resultsLimit={25}
                      setFilters={setFilters}
                      setPage={setPage}
                      searchBy={["eventUrl", "eventName"]}
                      filterBy="eventUrl"
                      idField="_id"
                      scrollLimit={200}
                      onRowClick={(event) => {
                        setCurrentEvent(null);
                        navigate(`/events/${event.eventUrl}/action/eventsinfo`)
                      }}
                    />
                  </Grid> */}
                  <Grid item sm={12}>
                    {/* <Searchbar
                      fetch={fetchInterviewEvents}
                      fetchAll
                      placeholder="Search Interview/Venue/City"
                      columns={interviewColumns}
                      queryCharacterLimit={1}
                      resultsLimit={10}
                      setFilters={setFilters}
                      setPage={setPage}
                      searchBy={["venueSlug", "eventName", "venueCity"]}
                      filterBy="venueSlug"
                      filterByColumn="venueSlug"
                      idField="eventUrl"
                      // onRowClick={(venue) => {
                      //   setCurrentEvent(null);
                      //   navigate(`/events/venue/${venue.slug}`)
                      // }
                      // }
                    /> */}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item sm={showSearchBar ? 4 : 10} alignItems="flex-end" flexWrap="nowrap">
              {/* // eslint-disable-next-line react/jsx-no-comment-textnodes
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <Grid container onClick={handleViewClick} justifyContent="center" flexWrap="nowrap">
                <Grid item>
                  {calendarListView === "Calendar" ? (
                    <ListIcon mt={1} color="primary" fontSize="large" />
                  ) : (
                    <DateRangeIcon color="success" fontSize="large" />
                  )}
                </Grid>
                <Grid item>
                  <Tooltip title="Click to Toggle Calendar/List View" justifyContent="right">
                    <MDTypography
                      variant="h5"
                      color="dark"
                      sx={{ ml: 1, mt: 1 }}
                      whiteSpace="nowrap"
                    >
                      {`Show ${calendarListView === "Calendar" ? "List" : "Calendar"} View`}
                    </MDTypography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
            <Grid item display="flex" sm={1} justifyContent="center" px={2}>
              <MDBox>
                <IconButton className={classes.addButton} color="info" onClick={addNew}>
                  <AddCircleRoundedIcon />
                </IconButton>
              </MDBox>
              <Tooltip
                title={
                  process.env.REACT_APP_ENV !== "production" ? "Create Report" : "Coming soon..."
                }
              >
                <IconButton
                  color="info"
                  onClick={
                    process.env.REACT_APP_ENV !== "production"
                      ? () => setOpenReportMessageModal(true)
                      : () => {}
                  }
                >
                  <ViewComfyIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MDBox pt={2} pb={2}>
        {calendarListView === "List" ? (
          <DataTable
            columns={columns}
            data={events}
            onRowClick={(row, e) => onRowClick(row, e)}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            defaultSort={defaultSort}
            fetchAll={fetchAll}
            isLoading={isLoading} // Extract isLoading from useQuery then pass here (required)
            greybar
            divider
            idField="_id"
          />
        ) : (
          <Grid container alignItems="center">
            <Grid item xs={12} style={{ maxHeight: 780, overflow: "scroll" }}>
              <EventCalendar
                events={events}
                isLoading={isLoading}
                sortByEventDate={sortByEventDate}
                calendarListView={calendarListView}
              />
            </Grid>
          </Grid>
        )}
      </MDBox>
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="events"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveJobReport}
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};
JobsEventsTable.defaultProps = {
  title: "Events",
  showFiltersList: true,
};

// Typechecking props
JobsEventsTable.propTypes = {
  title: PropTypes.string,
  showFiltersList: PropTypes.bool,
};

export default JobsEventsTable;
