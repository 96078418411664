import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { useAppContextController } from "context/AppContext";
import fetchJobs from "layouts/pages/jobs/actions/fetchJobs";
import useSort from "utils/useSort";

import FiltersList from "components/FiltersList";
import Searchbar from "components/Searchbar";
import VenueIcon from "components/VenueIcon";
import CompanyLogoSquare from "components/CompanyLogoSquare";
import fetchCustomers from "api/companies/fetchCustomers";
import useSessionAuth from "hooks/useSessionAuth";
import moment from "moment";
import DateRangeSelector from "components/DateRangeSelector";
import usePreferredPageSize from "hooks/usePreferredPageSize";
import ViewComfyIcon from "@mui/icons-material/ViewComfy";
import ReportModal from "components/ReportModal";
import {
  generateApplicantJobChartReport,
  generateApplicantJobTableReport,
  generateEmployeeJobChartReport,
  generateEmployeeJobTableReport,
  generateJobChartReport,
  generateJobTableReport,
  generateVenueJobChartReport,
  generateVenueJobTableReport,
} from "api/reports/jobReport";
import { exportReport } from "api/reports/exportReport";
import { saveReport } from "api/reports/saveReport";
import fetchModuleReports from "api/reports/fetchModuleReports";
import { defaultReportModalValues } from "components/ReportModal/fixtures";
import JobsTableActions from "../JobsTableActions";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const JobsTable = ({
  jobSlug,
  fetchAll,
  setNavigateUrl,
  filters,
  setFilters,
  setOpen,
  setJobPreview,
  setActionSelected,
  showFiltersList = true,
  showSearchBar = true,
  refetchJobs,
  title = "Stadium People Jobs",
  parent = "Jobs",
  currentApplicant,
  setToastAlert,
}) => {
  const { navigate } = useNavigate();
  const classes = useStyle();
  const { company, currentJob, setCurrentJob, venues, reportSchemas } = useAppContextController();
  const [customerData, setCustomerData] = useState({});
  const isCompany = company?.companyType === "Company";

  const [dateRangeFilter, setDateRangeFilter] = useState(null);
  const [openReportMessageModal, setOpenReportMessageModal] = useState(false);
  const [tableQueryDetails, setTableQueryDetails] = useState(null);
  const [chartQueryDetails, setChartQueryDetails] = useState(null);
  const [tableReportData, setTableReportData] = useState(null);
  const [chartReportData, setChartReportData] = useState(null);
  const [reportModalValues, setReportModalValues] = useState(defaultReportModalValues);

  const { logoutAuth0User } = useSessionAuth();

  const { action } = useParams();

  // Pagination state
  const [page, setPage] = useState(1);
  const { limit, setLimit, pageLimitConfirmationModal } = usePreferredPageSize(5);
  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll } : { page, limit, order, orderBy };
  const allOptions = { ...options, filters: { ...filters }, venues };

  const { mutateAsync: createJobTableReport } = useMutation(generateJobTableReport);
  const { mutateAsync: createJobChartReport } = useMutation(generateJobChartReport);
  const { mutateAsync: createVenueJobTableReport } = useMutation(generateVenueJobTableReport);
  const { mutateAsync: createVenueJobChartReport } = useMutation(generateVenueJobChartReport);
  const { mutateAsync: createApplicantJobTableReport } = useMutation(
    generateApplicantJobTableReport
  );
  const { mutateAsync: createApplicantJobChartReport } = useMutation(
    generateApplicantJobChartReport
  );
  const { mutateAsync: createEmployeeJobTableReport } = useMutation(generateEmployeeJobTableReport);
  const { mutateAsync: createEmployeeJobChartReport } = useMutation(generateEmployeeJobChartReport);
  const { mutateAsync: exportJobReport, isLoading: isLoadingExport } = useMutation(exportReport);
  const { mutateAsync: saveJobReport, isLoading: isLoadingSave } = useMutation(saveReport);

  const [chartTableSrc, setChartTableSrc] = useState(null);
  const [chartSrc, setChartSrc] = useState(null);

  const getLatestCriteria = (_tableReportData, _chartReportData, defaultValues) => {
    const tableCriteriaExists = _tableReportData?.criteria !== null;
    const chartCriteriaExists = _chartReportData?.criteria !== null;

    const tableTimestamp = tableCriteriaExists
      ? moment(_tableReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;
    const chartTimestamp = chartCriteriaExists
      ? moment(_chartReportData.timestamp, "YYYY-MM-DDTHH:mm:ss.SSSZ")
      : null;

    if (tableTimestamp && chartTimestamp) {
      return tableTimestamp.isAfter(chartTimestamp)
        ? _tableReportData.criteria
        : _chartReportData.criteria;
    }

    if (tableTimestamp && tableCriteriaExists) {
      return _tableReportData.criteria;
    }

    if (chartTimestamp && chartCriteriaExists) {
      return _chartReportData.criteria;
    }

    return defaultValues;
  };

  const handleLoadInitialJobReport = useCallback(async () => {
    if (openReportMessageModal && process.env.REACT_APP_ENV !== "production") {
      try {
        let reportModule;

        if (filters?.venueSlug) {
          reportModule = `venue-jobs:${filters?.venueSlug}`;
        } else if (parent === "applicants") {
          reportModule = `applicant-jobs:${currentApplicant?._id}`;
        } else if (parent === "employees") {
          reportModule = `employee-jobs:${currentApplicant?._id}`;
        } else {
          reportModule = `jobs`;
        }

        const response = await fetchModuleReports(reportModule);

        const latestCriteria = getLatestCriteria(
          response?.tableReportData,
          response?.chartReportData,
          defaultReportModalValues
        );

        setReportModalValues(latestCriteria);

        // Set table details
        if (response?.tableReportData) {
          setChartTableSrc(response.tableReportData.queryDetails.iframeSrc);
          setTableQueryDetails(response.tableReportData.queryDetails);
          setTableReportData(response.tableReportData);
        }

        // Set chart details
        if (response?.chartReportData) {
          setChartSrc(response.chartReportData.queryDetails.iframeSrc);
          setChartQueryDetails(response.chartReportData.queryDetails);
          setChartReportData(response.chartReportData);
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openReportMessageModal]);

  useEffect(() => {
    handleLoadInitialJobReport();
  }, [handleLoadInitialJobReport]);

  if (dateRangeFilter) {
    allOptions.startDate = dateRangeFilter.startDate.toISOString();
    allOptions.endDate = dateRangeFilter.endDate.toISOString();
  }

  const handleExportReport = useCallback(
    async (reportType, fileType) => {
      try {
        const payload = {
          queryId: reportType === "table" ? tableQueryDetails?.id : chartQueryDetails?.id,
          fileType,
        };

        await exportJobReport(payload, {
          onSuccess: async (response) => {
            setToastAlert({
              isShow: true,
              message: `Job ${reportType === "table" ? "Table" : "Chart"} ${
                fileType === "csv" ? " CSV" : " Excel"
              } Report has been successfully exported!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error exporting report:", error);
      }
    },
    [chartQueryDetails?.id, exportJobReport, setToastAlert, tableQueryDetails?.id]
  );

  const handleGenerateReport = async (values) => {
    if (values?.formType === "table") {
      let reportPayload = {};

      // Add columns to payload if any are selected
      if (values?.tableColumns?.length > 0) {
        reportPayload = { ...reportPayload, columns: values?.tableColumns };
      }

      // Add sortBy and order to payload if available
      if (values?.sortBy) {
        reportPayload = { ...reportPayload, sortBy: values.sortBy };
      }

      if (values?.order) {
        reportPayload = { ...reportPayload, order: values.order };
      }

      if (values?.dateRange) {
        reportPayload = {
          ...reportPayload,
          dateInfo: {
            start: values?.startDate, // ISO formatted date already handled
            end: values?.endDate, // ISO formatted date already handled
            field: values?.dateField,
            range: values?.dateRange,
          },
        };
      }

      // Add filters to payload if any filters are provided
      if (values?.filters) {
        reportPayload = {
          ...reportPayload,
          filters: values?.filters,
        };
      }

      if (values?.filterCards) {
        reportPayload = {
          ...reportPayload,
          filterCards: values?.filterCards,
        };
      }

      try {
        if (filters?.venueSlug) {
          reportPayload = {
            ...reportPayload,
            venueSlug: filters?.venueSlug,
          };

          await createVenueJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Venue Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "applicants") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createApplicantJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Applicant Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "employees") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createEmployeeJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Employee Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else {
          await createJobTableReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartTableSrc(response?.iframe_url);
                setTableQueryDetails(response?.queryDetails);
                setTableReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Job Table Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating table report:", error);
      }
    } else {
      let reportPayload = {
        xAxis: values?.xAxis,
        yAxis: values?.yAxis,
        groupBy: values?.groupBy,
        chartType: values?.chartType,
      };

      try {
        if (filters?.venueSlug) {
          reportPayload = {
            ...reportPayload,
            venueSlug: filters?.venueSlug,
          };

          await createVenueJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "applicants") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createApplicantJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Applicant Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else if (parent === "employees") {
          reportPayload = {
            ...reportPayload,
            applicantId: currentApplicant?._id,
          };

          await createEmployeeJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Employee Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        } else {
          await createJobChartReport(reportPayload, {
            onSuccess: async (response) => {
              if (response?.iframe_url) {
                setChartSrc(response?.iframe_url);
                setChartQueryDetails(response?.queryDetails);
                setChartReportData(response?.reportData);
              }

              setToastAlert({
                isShow: true,
                message: "Job Chart Report has been successfully generated!",
                status: "success",
              });
            },
            onError: (err) => {
              setToastAlert({
                isShow: true,
                message: `Something went wrong! ${err}`,
                status: "error",
              });
            },
          });
        }
      } catch (error) {
        console.error("Error generating chart report:", error);
      }
    }
  };

  const {
    data: jobs,
    isLoading,
    refetch,
  } = useQuery(["jobs", allOptions], () => fetchJobs(allOptions), {
    onSuccess: async (data) => {
      if (!isCompany) return;
      const customers = {};
      try {
        // Get all company slugs
        let companySlugs = data?.data?.map((comp) => comp.companySlug);
        // Remove duplicates
        companySlugs = [...new Set(companySlugs)];

        const results = await fetchCustomers({
          filters: { slug: companySlugs.join(";") },
          page: 1,
          limit: 0,
        });

        // Do something with all fetched customer data

        // Assuming that 'results' is an array of objects and each object has a 'slug' property
        (results?.data ?? []).forEach((cstmr) => {
          customers[cstmr.slug] = cstmr;
        });
        setCustomerData(customers);
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    },
  });

  const handleSaveJobReport = useCallback(
    async (values) => {
      try {
        let payload = {
          criteria: values,
        };

        let _tableReportData = tableReportData;
        let _chartReportData = chartReportData;

        if (filters?.venueSlug) {
          _tableReportData = {
            ..._tableReportData,
            feature: `venue-jobs:${filters?.venueSlug}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `venue-jobs:${filters?.venueSlug}`,
          };
        } else if (parent === "applicants") {
          _tableReportData = {
            ..._tableReportData,
            feature: `applicant-jobs:${currentApplicant?._id}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `applicant-jobs:${currentApplicant?._id}`,
          };
        } else if (parent === "employees") {
          _tableReportData = {
            ..._tableReportData,
            feature: `employees-jobs:${currentApplicant?._id}`,
          };

          _chartReportData = {
            ..._chartReportData,
            feature: `employees-jobs:${currentApplicant?._id}`,
          };
        }

        if (values?.formType === "table") {
          payload = { ...payload, tableReportData: _tableReportData, formType: "table" };
        } else if (values?.formType === "chart") {
          payload = { ...payload, chartReportData: _chartReportData, formType: "chart" };
        }

        await saveJobReport(payload, {
          onSuccess: async (response) => {
            if (response?.iframe_url) {
              setChartSrc(response?.iframe_url);
              setChartQueryDetails(response?.queryDetails);
              setChartReportData(response?.reportData);
            }

            setToastAlert({
              isShow: true,
              message: `Job ${
                values?.formType === "table" ? "Table" : "Chart"
              } Report has been successfully saved!`,
              status: "success",
            });
          },
          onError: (err) => {
            setToastAlert({
              isShow: true,
              message: `Something went wrong! ${err}`,
              status: "error",
            });
          },
        });
      } catch (error) {
        console.error("Error saving job report:", error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartReportData, saveJobReport, tableReportData]
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchJobs]);

  useEffect(() => {
    if (!isLoading && jobs?.data?.length && jobSlug) {
      const ndx = jobs.data.findIndex((item) => item.jobSlug === jobSlug);
      if (ndx > -1) {
        setCurrentJob(jobs.data[ndx]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, jobSlug, jobs?.data]);

  useEffect(() => {
    setPage(1);
  }, [action, filters]);

  const columns = useMemo(
    () => [
      {
        title: "",
        field: "logoUrl",
        customCell: (logoUrl, field, row) => {
          if (isCompany) {
            const rowCompany = customerData[row.companySlug] ?? {};
            return <CompanyLogoSquare company={rowCompany} defaultCompany={company} />;
          }
          return <VenueIcon logoUrl={logoUrl} slug={row?.venueSlug} name={row?.venueName} />;
        },
      },
      ...(!isCompany
        ? [
            {
              title: "Venue",
              field: "venueSlug",
              customCell: (slug) => <span>{slug?.toUpperCase()}</span>,
            },
          ]
        : []),
      { title: "Job Url", field: "jobSlug" },
      { title: "Title", field: "title" },
      {
        title: "Pay Rate",
        field: "payRate",
        customCell: (rate) => `$${rate?.toFixed(2)}`,
      },
      {
        title: "Shift Job",
        field: "shiftJob",
        customCell: (shiftJob) => `${shiftJob === "Yes" ? "Yes" : "No"}`,
      },
      {
        title: "Date Created",
        field: "createdDate",
        customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
      },
      // {
      //   title: "Start Date",
      //   field: "startDate",
      //   customCell: (date) => moment(date).format("MM/DD/YYYY"),
      // },
      { title: "Status", field: "status" },
      {
        title: "Job Actions",
        field: "jobSlug",
        onClick: (e) => e.stopPropagation(),
        customCell: (thisJobSlug, field, row) => {
          // if (parent === "jobs")
          return (
            <JobsTableActions
              jobSlug={thisJobSlug}
              setNavigateUrl={navigate}
              row={row}
              setActionSelected={setActionSelected}
              setOpen={setOpen}
              setJobPreview={setJobPreview}
              parent={parent}
              currentApplicant={currentApplicant}
              isCompany={isCompany}
            />
          );
        },
      },
    ],
    [
      isCompany,
      customerData,
      company,
      navigate,
      setActionSelected,
      setOpen,
      setJobPreview,
      parent,
      currentApplicant,
    ]
  );

  const handleRowClick = (job, e) => {
    e.stopPropagation();
    setCurrentJob(job);
    setNavigateUrl(`/jobs/${job.jobSlug}`);
  };

  const addNew = () => {
    if (!currentJob) {
      setCurrentJob({});
    }
    setNavigateUrl("/jobs/create");
  };

  return (
    <Card className={classes.box}>
      <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1} pr={2}>
        <Grid item>
          <MDBox display="flex">
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="4rem"
              height="4rem"
              variant="gradient"
              bgColor="warning"
              color="white"
              borderRadius="xl"
              ml={3}
              mt={-6}
            >
              <Icon fontSize="large">work</Icon>
            </MDBox>
            <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
              {title}
            </MDTypography>
            <MDBox ml={3}>
              {showFiltersList && (
                <Grid item>
                  <FiltersList filters={filters} setFilters={setFilters} />
                </Grid>
              )}
            </MDBox>
          </MDBox>
        </Grid>
        <Grid item display="flex" alignItems="center">
          <DateRangeSelector
            setDateRangeFilter={setDateRangeFilter}
            dateRangeFilter={dateRangeFilter}
          />
          {showSearchBar && (
            <Searchbar
              fetch={fetchJobs}
              fetchAll={false}
              placeholder="Search Job/Venue"
              columns={columns.slice(1, columns.length - 1)}
              queryCharacterLimit={2}
              resultsLimit={10}
              setFilters={setFilters}
              setPage={setPage}
              searchBy={["title", "venueName", "venueSlug"]}
              filterBy="venueSlug"
            />
          )}
          <MDBox>
            <IconButton className={classes.addButton} color="info" onClick={addNew}>
              <AddCircleRoundedIcon />
            </IconButton>
          </MDBox>
          <Tooltip
            title={process.env.REACT_APP_ENV !== "production" ? "Create Report" : "Coming soon..."}
          >
            <IconButton
              color="info"
              onClick={
                process.env.REACT_APP_ENV !== "production"
                  ? () => setOpenReportMessageModal(true)
                  : () => {}
              }
            >
              <ViewComfyIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <MDBox pt={2} pb={2}>
        <DataTable
          columns={columns}
          data={jobs}
          page={page}
          limit={limit}
          setPage={setPage}
          setLimit={setLimit}
          onRowClick={(row, e) => handleRowClick(row, e)}
          order={order}
          orderBy={orderBy}
          toggleSort={toggleSort}
          fetchAll={fetchAll}
          isLoading={isLoading}
          defaultSort="jobSlug"
          idField="jobSlug"
        />
      </MDBox>
      {pageLimitConfirmationModal}

      {openReportMessageModal && (
        <ReportModal
          reportingType="jobs"
          message="Coming Soon!"
          isOpen={openReportMessageModal}
          toggleOpen={setOpenReportMessageModal}
          handleGenerateReport={handleGenerateReport}
          chartTableSrc={chartTableSrc}
          chartSrc={chartSrc}
          handleExportReport={handleExportReport}
          handleSaveReport={handleSaveJobReport}
          filterCardList={
            ["applicants", "employees"].includes(parent) || filters?.venueSlug
              ? []
              : ["Active", "Inactive", "Hidden", "All"]
          }
          isLoadingExport={isLoadingExport}
          isLoadingSave={isLoadingSave}
          reportModalValues={reportModalValues}
        />
      )}
    </Card>
  );
};

// Setting default values for the props
JobsTable.defaultProps = {
  fetchAll: true,
  setNavigateUrl: () => {},
  title: "Jobs Table",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
JobsTable.propTypes = {
  fetchAll: PropTypes.bool,
  setNavigateUrl: PropTypes.func,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default JobsTable;
