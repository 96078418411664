import { Controller } from "react-hook-form";
import { Grid, Stack, Typography } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";

const ShiftFormDayTimeController = ({ control, label, day, index }) => {
  return (
    <Grid item sm={1.5}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2">{label}</Typography>
      </Stack>

      <Controller
        name={`shifts[${index}].defaultSchedule.${day}.start`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              value={!field.value ? null : field.value}
              onChange={(date) => {
                field.onChange(new Date(date));
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  autoComplete="off"
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
      <Controller
        name={`shifts[${index}].defaultSchedule.${day}.end`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <TimePicker
              value={!field.value ? null : field.value}
              onChange={(date) => {
                field.onChange(new Date(date));
              }}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  autoComplete="off"
                  showError
                  errorMessage={error?.message}
                />
              )}
            />
          </LocalizationProvider>
        )}
      />
    </Grid>
  );
};

export default ShiftFormDayTimeController;
