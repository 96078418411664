import { Controller } from "react-hook-form";
import { Grid } from "@mui/material";
import CustomTextField from "components/Form/CustomTextField";
import { NumericFormat } from "react-number-format";
import MDTypography from "components/MDTypography";
import ShiftFormDayTimeController from "layouts/pages/jobs/components/ShiftJobs/ShiftForm/ShiftFormDayTimeController";

const ShiftForm = ({ control, index }) => {
  console.log("ShiftForm -> index", index);
  const days = [
    { label: "MON", day: "monday" },
    { label: "TUE", day: "tuesday" },
    { label: "WED", day: "wednesday" },
    { label: "THU", day: "thursday" },
    { label: "FRI", day: "friday" },
    { label: "SAT", day: "saturday" },
    { label: "SUN", day: "sunday" },
  ];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Controller
          name={`shifts[${index}].shiftName`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <CustomTextField
              label="Shift Name"
              {...field}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name={`shifts[${index}].payRate`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericFormat
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              allowNegative={false}
              label="Pay Rate"
              decimalScale={2}
              {...field}
              customInput={CustomTextField}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          name={`shifts[${index}].billRate`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <NumericFormat
              InputProps={{
                startAdornment: "$",
                inputProps: {
                  inputMode: "numeric",
                },
              }}
              allowNegative={false}
              label="Bill Rate"
              decimalScale={2}
              {...field}
              customInput={CustomTextField}
              showError
              errorMessage={error?.message}
            />
          )}
        />
      </Grid>
      <Grid item sm={12}>
        <MDTypography variant="h5" color="info" mt={3} mb={2}>
          Weekly Schedule
        </MDTypography>
        <Grid container spacing={1}>
          <Grid item sm={1.5}>
            <MDTypography variant="h6" color="info" my={0.5}>
              DAYS
            </MDTypography>
            <MDTypography variant="h6" color="info" my={0.5}>
              START
            </MDTypography>
            <MDTypography variant="h6" color="info" my={0.5}>
              END
            </MDTypography>
          </Grid>
          {days.map((item, idx) => (
            <ShiftFormDayTimeController
              key={`${item.day}_day_${idx.toString()}`}
              control={control}
              label={item.label}
              day={item.day}
              index={index}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShiftForm;
